import React, { useEffect, useState } from 'react';

import SubjectGrade from './grade';

import { getSubjectCode } from '../../../utils/subjects';
import { Checkbox, Chip } from '@utdanningno/designsystem-react';
import { Select } from '@utdanningno/designsystem-react-select';

export default function ExpandedSubject({
  subject,
  value,
  setValue,
  setRemoveConfirm,
  chooseSubject,
  selected,
  options,
}) {
  const { id, name, position, exam, type, section, children } = subject;

  const [hasExam, setHasExam] = useState(null);
  useEffect(() => {
    if (value?.[1]) {
      setHasExam(true);
    }
  }, [value]);

  const setHasExamOption = opt => {
    setHasExam(opt);
    if (!opt && value?.[1]) {
      setValue('exam', null);
    }
  };

  return (
    <>
      <div className="subject__header">
        <span className="subject__id">
          Fagkode: {getSubjectCode(options, selected, id, children)}
        </span>
        <Chip
          close
          label={ `Fjern fag` }
          onClick={ () => setRemoveConfirm(true) }
        ></Chip>
      </div>
      <h4 className="subject__name">{name}</h4>

      {options &&
        <Select
          name={ `subject-option-${id}` }
          defaultValue={ options[0] }
          onChange={ val => chooseSubject(section, id, val.value) }
          options={ options }
        />
      }

      {position ? (
        <SubjectGrade
          name={ `position-${id}` }
          type={ type }
          value={ value?.[0] || null }
          setValue={ v => setValue('position', v) }
          selected={ selected }
        />
      ) : null}

      {position && exam ? (
        <Checkbox
          label="Jeg har tatt eksamen"
          name={ `taken-examn-${id}` }
          checked={ hasExam }
          onChange={ () => setHasExamOption(!hasExam) }
        />
      ) : null}

      {hasExam || (exam && !position) ? (
        <>
          {position && exam ? (
            <p className="small font-bold">Eksamenskarakter</p>
          ) : null}
          <SubjectGrade
            name={ `exam-${id}` }
            type={ type }
            value={ value?.[1] || null }
            setValue={ v => setValue('exam', v) }
            selected={ selected }
          />
        </>
      ) : null}
    </>
  );
}
